import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../utils/fx'
import Loader from './loader/Loader'

const initStats = {
    records: {playedGames: 0, bestTimes: [], totalWordsFound: 0, ongoingStreak: 0, bestStreak: 0},
    current: {allAnswerWords: [], results: [], gameStreak: 0, streak: 0, coreFound: [], validFound: [], time: 0}
}

export default function Stats({goBack, secs, daily, showTop, setShowTop}) {
    const [status, setStatus] = useState('')
    const [stats, setStats] = useState(initStats)
    // Loader
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        const {status, records, current} = ls
        const {allAnswerWords, results, gameStreak, streak, time, coreFound, validFound} = current
        setStatus(status)
        setStats({records, current: {allAnswerWords, results, gameStreak, streak, coreFound, validFound, time}})
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])

    const shareData = {
        title: 'Word Fuzzle',
        text: 'I\'m playing word fuzzle - wordfuzzle.com',
        url: 'https://www.wordfuzzle.com'
    }

    const handleShare = async(e) => {
        e.preventDefault()
        
        if(status === 'finish' || status === 'wait') shareData.text = getWinningShareMessage(stats.current.results, stats.current.time)

        try {
            if (navigator.share){
                await navigator.share(shareData);
            } else {
                const { ClipboardItem } = window
                if(ClipboardItem && navigator.clipboard.write){
                    const content = getWinningShareMessageHTML(stats.current.results, stats.current.time)
                    const blob = new Blob([content], { type: "text/html" })
                    await navigator.clipboard.write([new ClipboardItem({ "text/html" : blob })])
                } else {
                    await navigator.clipboard.writeText(shareData.text)
                }
                alert(`Copied: ${shareData.text}`)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getWinningShareMessage = (results, time) => {
        return `${results.map(el => el.core ? '🟩' : '🟦').join('')}  Completed daily fuzzle no: ${daily} in ${fx.formatTimeWithColons(time)}: Wordfuzzle.com`
    }

    const getWinningShareMessageHTML = (results, time) => {
        return <p>{`${results.map(el => el.core ? '🟩' : '🟦').join('')}  Completed daily fuzzle no: ${daily} in ${fx.formatTimeWithColons(time)}:`}<a style={{color: 'white'}} href='https://www.wordfuzzle.com'>Wordfuzzle.com</a> </p>
    }
    /*
    const btn = document.querySelector('.share-button');
    useEffect(() => {
        if(btn){
            btn.addEventListener('click', async () => {
                
            });
        }
    }, [btn])*/
    
    return (
        <section id='stats'>
            <div id='bubble'></div>
            {loader ? <div className='loader-container'><Loader /></div>
            :<>
            <div className='section-title flexrow'>
                <div className='empty'></div>
                <p className='title'>Statistics</p>
                <FontAwesomeIcon className='icon-close pointer' icon="fa-solid fa-circle-xmark" onClick={goBack}/>
            </div>
            <div className='section-content'>
                <div className='scores'>
                    <div className='score'>
                        <p>Best Time</p>
                        <p className='score-value'>{stats.records.bestTimes.length === 0 ? '-' : fx.formatTime(stats.records.bestTimes[0])}</p>
                    </div>
                    <div className='score'>
                        <p>Best Streak</p>
                        <p className='score-value'>{stats.records.bestStreak}</p>
                    </div>
                    <div className='score'>
                        <p>Played</p>
                        <p className='score-value'>{stats.records.playedGames}</p>
                    </div>
                </div>
                <div className='total-words-found'>
                    <p>Total Words Found: {stats.records.totalWordsFound}</p>
                </div>
                <div className='horizontal-bar'></div>
                <div className='scores-played'>
                    {showTop? 
                    <div className='recorded-times'>
                        <div className='title-container'>
                            <div className='empty'></div>
                            <p className='title'>{stats.records.playedGames === 0 ? 'No fuzzles played' : 'Top 10 quickest times'}</p>
                            <FontAwesomeIcon className='icon-close pointer' icon="fa-solid fa-circle-xmark" onClick={()=>setShowTop(false)}/>
                        </div>
                        {stats.records.playedGames !== 0 && 
                        <div className='top-times'>
                            {stats.records.bestTimes.map((time,idx,arr) => {
                                const max = Math.max(...arr)
                                const width = (time / max) * 100
                                return (
                                    <div key={idx} className='time'>
                                        <p className='formatted-time'>{fx.formatTime(time)}</p>
                                        <div style={{width: '100%'}}><div className='horizontal-time-bar' style={{width : `${width}%`}}></div></div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                    :<div className='flexcol'>
                        {status !== 'start' && <p className='daily-challenge-number'>Daily challenge no: {daily}</p>}
                        {status === 'start' && <p>Ready to play</p>}
                        {status === 'resume' && <p>Fuzzle in progress: {fx.formatTime(secs)}</p>}
                        {(status === 'finish' || status === 'wait') && <p>Fuzzle complete: {fx.formatTime(secs)}</p>}
                        <p>Current streak: {stats.current.gameStreak}</p>
                        <p>Core words found: {stats.current.coreFound.length > 0 ? stats.current.coreFound.toString() : '-'}</p>
                        {((status === 'finish' || status === 'wait') && (stats.current.coreFound.length < stats.current.allAnswerWords.length)) && <p>Missing core words {stats.current.allAnswerWords.filter(el => !stats.current.coreFound.includes(el)).join(',')}</p>}
                        <p>Valid words found: {stats.current.validFound.length > 0 ? stats.current.validFound.length : '-'}</p>
                        <div className='valid-words-found-container'>
                            {stats.current.validFound.join(' ')}
                        </div>
                    </div>}
                </div>
                <div className='horizontal-bar'></div>
                <div className='stats-bottom'>
                    <button className='top-10-times-button' onClick={()=>setShowTop(true)}>Top 10 times</button>
                    <button id='share-button' className='share-button' onClick={handleShare}>SHARE <FontAwesomeIcon icon="fa-solid fa-share-nodes" /></button>
                </div>
            </div>
            </>}
        </section>
    )
}
