import React, {useState, useEffect} from 'react'
import fx from '../utils/fx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from './loader/Loader'

export default function Settings({goBack, maxWords, updateSettings, settings}) {
    // Loader
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        //const ls = JSON.parse(localStorage.getItem('wf-game'))
        updateSettings(settings)
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])

    const changeSetting = (e) => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        const [name, value] = e.target.name.split(' ')
        if(!value) {
            if(e.target.type === 'checkbox') ls.settings[name] = e.target.checked
        } else {
            ls.settings[name] = value
        }
        
        if(ls.status !== 'finish' && ls.status !== 'wait' && (name === 'wordsToSolve' || name === 'finishWithCore')) {
            if(name === 'finishWithCore') ls.current.tiles = ls.current.tiles.map(el => ({...el, left: el.t}))
            if(name === 'wordsToSolve'){
                if(Number(value) === maxWords) {
                    ls.current.answerWords = ls.current.allAnswerWords
                    ls.current.tiles = fx.getTiles(ls.current.allAnswerWords)
                } else {
                    const oneFound = [ls.current.streak[0], ls.current.allAnswerWords.find(el => el !== ls.current.streak[0])]
                    ls.current.answerWords = ls.current.streak.length === maxWords ? ls.current.allAnswerWords : ls.current.streak.length === maxWords - 1 ? ls.current.streak : ls.current.streak.length === 1 ? oneFound : ls.current.allAnswerWords.slice(0,2)
                    ls.current.tiles = ls.current.streak.length === maxWords ? fx.getTiles(ls.current.allAnswerWords) : fx.getTiles(ls.current.answerWords)
                }
            }
            ls.current.guesses = []
            ls.current.pos = {row: 0, col: 0}
            ls.current.results = []
            ls.current.msg = ''
            //ls.current.streak = []
            //ls.current.validFound = []
        }
        updateSettings(ls.settings)
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
    }
    
    return (
        <section id='settings'>
            {loader ? <div className='loader-container'><Loader /></div>
            :<>
            <div className='section-title flexrow'>
                <div className='empty'></div>
                <p className='title'>Settings</p>
                <FontAwesomeIcon className='icon-close pointer' icon="fa-solid fa-circle-xmark" onClick={goBack}/>
            </div>
            <div className='section-content'>
                <p className='centered-text'>No of words to solve</p>
                <p className='centered-text'>restarts fuzzles</p>
                <div className='number-of-words'>
                    <button name='wordsToSolve 2' className={Number(settings.wordsToSolve) === 2 ? 'selected-button' : ''} onClick={changeSetting}>2 words</button>
                    <button name='wordsToSolve 3' className={Number(settings.wordsToSolve) === 3 ? 'selected-button' : ''} onClick={changeSetting}>3 words</button>
                </div>
                <div className='horizontal-bar'></div>
                <div className='options'>
                    <div className='option'>
                        <p>Finish with core words only {`(hard)`}</p>
                        <Toogle n={'t1'} name='finishWithCore' checked={settings.finishWithCore} change={changeSetting}/>
                    </div>
                    <div className='option'>
                        <p>Dark mode</p>
                        <Toogle n={'t2'} name='darkMode' checked={settings.darkMode} change={changeSetting}/>
                    </div>
                    <div className='option'>
                        <p>High contrast colours</p>
                        <Toogle n={'t3'} name='highContrastColours' checked={settings.highContrastColours} change={changeSetting}/>
                    </div>
                </div>
                <a className='feedback-email' href="mailto:wordfuzzle@gmail.com">feedback {'('}email{')'}</a>
            </div>
            </>}
        </section>
    )

    function Toogle({n, name, checked, change}) {
        return (
            <>
                <span className='check'></span>
                <input type="checkbox" id={n} name={name} checked={checked} onChange={change}/>
            </>
        )
    }
}
