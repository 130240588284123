import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fx from '../utils/fx'

import Lottie from 'react-lottie'
import Tile from './tile/Tile'
import Loader from './loader/Loader'

import erase from '../media/erase.png'
import darkErase from '../media/dark-erase.png'
import validWords from '../words/validWords.json'
import * as animationData from '../media/flags.json'

const clearTimeInSecs = 0.5

export default function Game({mwl, finishGame, setShowTop, settings}) {
    const [gameParameters, setGameParameters] = useState({rows: Number(settings.wordsToSolve), columns: mwl})
    const [tiles, setTiles] = useState([])
    const [guesses, setGuesses] = useState([])
    const [answerWords, setAnswerWords] = useState([])
    const [results, setResults] = useState([])
    const [pos, setPos] = useState({row: 0, col: 0})
    const [msg, setMsg] = useState('')
    const [capture, setCapture] = useState(0)
    const [countTime, setCountTime] = useState(false)

    // Loader
    const [loader, setLoader] = useState(true)

    //Callback
    const [cb, setCB] = useState(false)

    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        ls.status === 'start' && (ls.status = 'resume')
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])
    
    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        if(ls) {
            const {settings, current} = ls
            setGameParameters({...gameParameters, rows: Number(settings.wordsToSolve)})
            setTiles(current.tiles)
            setAnswerWords(current.answerWords)
            setGuesses(current.guesses)
            setPos({row: current.pos.row, col: current.pos.col})
            setMsg(current.msg)
            setResults(current.results)
        }
    }, [localStorage.getItem('wf-game'), cb])

    useEffect(() => {
        // Evaluate win condition
        const win = settings.finishWithCore ? results.filter(el => el.core).length === gameParameters.rows : // Only core words
        results.filter(el => el.good).length === gameParameters.rows // Any valid word
        
        if(win) {
            const finished = document.getElementById('finished')
            const notFinished = document.getElementById('not-finished')
            notFinished.style.display = 'none'
            finished.style.display = 'flex'
            setShowTop(false)
            finishGame()
        }
    }, [results])
    

    const placeLetter = (idx) => {
        const {row, col} = pos
        const currentLetter = document.getElementById(`${row}${col}`).innerHTML
        if(row === gameParameters.rows - 1 && col === gameParameters.columns - 1 && currentLetter !== '') return

        const ls = JSON.parse(localStorage.getItem('wf-game')) // reads local storage for the game
        let newRow = row
        let newCol = col
        if(currentLetter === ''){
            document.getElementById(`${row}${col}`).innerHTML = ls.current.tiles[idx].l.toUpperCase()
        } else{
            newRow = col === gameParameters.columns - 1 ? row + 1 : row
            newCol = col === gameParameters.columns - 1? 0 : col + 1
            document.getElementById(`${newRow}${newCol}`).innerHTML = ls.current.tiles[idx].l.toUpperCase()
        }
        ls.current.tiles[idx].left -= 1
        ls.current.guesses.push(ls.current.tiles[idx].l.toUpperCase())

        if(ls.current.guesses.length % gameParameters.columns === 0) {
            // Evaluate guess
            const guess = ls.current.guesses.filter((el, idx) => idx >= row * gameParameters.columns && idx < (row + 1)* gameParameters.columns).join('')
            if(validWords.includes(guess)) {
                if(answerWords.includes(guess) && !ls.current.coreFound.includes(guess)){
                    ls.current.coreFound.push(guess) // Saving core words
                    ls.current.streak += 1
                }
                if(!answerWords.includes(guess) && !ls.current.validFound.includes(guess)){
                    ls.current.validFound.push(guess) //Saving valid words
                    ls.current.streak += 1
                }
                if(ls.current.streak > ls.current.gameStreak) ls.current.gameStreak = ls.current.streak // Saving new game streak
                ls.current.results.push({guess, good: true, core: answerWords.includes(guess)})
                if(settings.finishWithCore && ls.current.results.length === gameParameters.rows && ls.current.coreFound.length < gameParameters.rows) ls.current.msg = 'Need to find all the core words'
            } else {
                ls.current.streak = 0
                ls.current.results.push({guess, good: false})
                ls.current.msg = 'Word not found'
                badGuessAnimation()
            }
        }

        ls.current.pos = {row: newRow, col: newCol}
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
        setCB(!cb)
    }

    const eraseLetter = () => {
        const {row, col} = pos
        const currentLetter = document.getElementById(`${row}${col}`).innerHTML
        if(row === 0 && col === 0 && currentLetter === '') return

        const ls = JSON.parse(localStorage.getItem('wf-game')) // reads local storage for the game

        document.getElementById(`${row}${col}`).innerHTML = ''
        ls.current.tiles[tiles.findIndex(el => el.l === currentLetter)].left += 1
        ls.current.guesses.pop()
        if(ls.current.guesses.length % gameParameters.columns === gameParameters.columns - 1) ls.current.results.pop()
        let newRow = col === 0 && row !== 0 ? row - 1 : row
        let newCol = col === 0 ? row === 0 ? 0: gameParameters.columns - 1 : col - 1
        ls.current.pos = {row: newRow, col: newCol}
        ls.current.msg = ''
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
        setCB(!cb)
    }

    const clearRow = () => {
        const {row, col} = pos
        const ls = JSON.parse(localStorage.getItem('wf-game')) // reads local storage for the game
        if(row === 0 && col === 0 && document.getElementById(`${row}${col}`).innerHTML === '') return
        for(let i=col; i>=0; i--) {
            const currentLetter = document.getElementById(`${row}${i}`).innerHTML
            ls.current.tiles[tiles.findIndex(el => el.l === currentLetter)].left += 1
            ls.current.guesses.pop()
            let newRow = row === 0 ? 0 : row - 1
            let newCol = row === 0 ? 0 : gameParameters.columns - 1
            ls.current.pos = {row: newRow, col: newCol}
            ls.current.msg = ''
            document.getElementById(`${row}${col}`).innerHTML = ''
        }
        if(col === gameParameters.columns - 1) ls.current.results.pop()
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
        setCB(!cb)
    }

    const handleOnMouseDown = (e) => {
        e.preventDefault()
        setCapture(Date.now())
        setCountTime(true)
    }

    useEffect(() => {
        const passed = Date.now() - capture
        if(countTime && (passed > clearTimeInSecs * 1000)) {
            clearRow()
            setCountTime(false)
        }
    }, [Date.now()])
    

    const handleOnMouseUp = (e) => {
        e.preventDefault()
        const passed = Date.now() - capture
        if(passed > clearTimeInSecs * 1000) return
        eraseLetter()
        setCountTime(false)
    }

    const shuffleTiles = () => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        ls.current.tiles = fx.shuffleArray(ls.current.tiles)
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
        setCB(!cb)
    }

    const badGuessAnimation = () => {
        for(let i=0; i< gameParameters.columns; i++) document.getElementById(`${pos.row}${i}`).style.backgroundColor = 'rgb(128,0,0)'
        setTimeout(() => {
            for(let i=0; i< gameParameters.columns; i++) document.getElementById(`${pos.row}${i}`).style.backgroundColor = 'rgb(74, 74, 74)'
        }, 200);
    }

    const getFinishedMsg = () => {
        const ls = JSON.parse(localStorage.getItem('wf-game')) // reads local storage for the game
        const {time} = ls.current
        let msg = 'well done'
        if(time < 60) msg = 'excellent time'
        if(time >= 60 && time < 120) msg = 'brilliant time'
        if(time >= 120 && time < 180) msg = 'great time'
        if(time >= 180 && time < 240) msg = 'splendid time'
        return `Fuzzle complete - ${msg}`
    }

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    
    return (
        <div id='game'>
            {loader ? <div className='loader-container'><Loader /></div>
            :<>
            <div className='board'>
                {Array.from(Array(gameParameters.rows).keys()).map(row => {
                    return (
                        <div key={row} className='row'>
                            {Array.from(Array(gameParameters.columns).keys()).map(col => {
                                return <div key={col} id={`${row}${col}`} className='card' style={{backgroundColor: results.length > 0 && results[row]?.good ? results[row].core ? 'rgb(83, 141, 79)' : 'rgb(0, 119, 187)' : ''}}>{guesses[col + row * gameParameters.columns]}</div>
                            })}
                        </div>
                    )
                })}
            </div>
            <div id='not-finished' className='tiles-container'>
                <div className='msg'>
                    {msg && <p>{msg}</p>}
                </div>
                <div className='tiles'>
                    {tiles.map((tile,idx) => {
                        return (
                            <Tile key={idx} demo={false} idx={idx} letter={tile.l} times={tile.left} placement={placeLetter} dark={settings.darkMode} highContrast={settings.highContrastColours}/>
                        )
                    })}
                </div>
                <div className='game-bottom'>
                    <div className='shuffle-button' onClick={shuffleTiles}><FontAwesomeIcon icon="fa-solid fa-shuffle" /></div>
                    <div id='erase-button' className='erase-button' style={{backgroundImage: settings.darkMode ? `url(${darkErase})` : `url(${erase})`, backgroundSize: '100% 100%'}} onMouseDown={handleOnMouseDown} onMouseUp={handleOnMouseUp} onTouchStart={handleOnMouseDown} onTouchEnd={handleOnMouseUp}></div>
                </div>
            </div>
            <div id='finished'>
                <div className='msg'><p>{getFinishedMsg()}</p>
                </div>
                <Lottie
                    options = {defaultOptions}
                />
            </div>
            </>}
        </div>
    )
}
