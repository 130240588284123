import React, {useState, useEffect} from 'react'
import fx from '../utils/fx'
import Loader from './loader/Loader'

import logo from '../media/logo.svg'
import darkLogo from '../media/dark-logo.svg'

export default function Home({goGame, settings, secsTillMidnight, daily}) {
    const [status, setStatus] = useState('')

    // Loader
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        if(!ls) return
        setStatus(ls.status)
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])

    return (
        <section id='home'>
            {loader ? <div className='loader-container'><Loader /></div>
            :<>
            <div className='section-content'>
                <img src={settings.darkMode ? darkLogo : logo} alt='name'/>
                {status !== 'finish' && status !== 'wait' ? 
                <button className='button-home' onClick={goGame}>{status === 'start' ? `Play daily challenge: ${daily}` : 'Resume daily challenge'}</button>
                :
                <>
                    <button className='button-home'>Wait for next challenge</button>
                    <p className='countdown'>Next daily fuzzle in {fx.formatTimeWithColons(secsTillMidnight)}</p>
                </>}
            </div>
            <div className='home-bottom'>
                <p className='home-date-day'>{new Date().toLocaleDateString('en-US', { weekday: 'long'})}</p>
                <p className='home-date-rest'>{new Date().toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
            </div>
            </>}
        </section>
    )
}
