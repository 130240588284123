import React, {useState, useEffect, useRef} from 'react'
import fx from './utils/fx';

import Header from './components/Header';
import Home from './components/Home';
import Settings from './components/Settings';
import Instructions from './components/Instructions'
import Stats from './components/Stats';
import Game from './components/Game';
import Loader from './components/loader/Loader';

import './App.css';

import words from './words/validWords.json'
import positions from './words/positions.json'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartSimple, faCircleXmark, faGear, faHouseChimney, faQuestionCircle, faShareNodes, faShuffle, faXmark } from '@fortawesome/free-solid-svg-icons'

library.add(faHouseChimney, faGear, faChartSimple, faQuestionCircle, faCircleXmark, faShareNodes, faShuffle, faXmark)

const maxWordsToSolve = 3
const maxWordLength = 5
const numberOfTopTimesToRecord = 10
const animationTimeInSecs = 3
const launchDate = '9/2/2022'

const findTodayNumber = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), 0, 0);
  const diff = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day
}

const getGameWords = (wordsList, positions, dayNumber) => {
  return positions[dayNumber-1].split(',').map(pos => wordsList[Number(pos)-1].toUpperCase())
}

const initSettings = {
  wordsToSolve: maxWordsToSolve,
  finishWithCore: false,
  darkMode: true,
  highContrastColours: false
}

function App() {
  const [showTop, setShowTop] = useState(true)
  const [dailyNo, setDailyNo] = useState(0)
  const [settings, setSettings] = useState(initSettings)

  // Tracking time
  const [time, setTime] = useState(Date.now())
  const [trackingTime, setTrackingTime] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [secsTillMidnight, setSecsTillMidnight] = useState(0)
  const [page, setPage] = useState('home')

  // Loader
  const [initialLoad, setInitialLoad] = useState(true)
  const interval = useRef(null)

  let nextMidnight = new Date();
  nextMidnight.setHours(24,0,0,0);
  
  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('wf-game'))
    const wordsPositions = positions[`y${new Date().getFullYear()}`]
    const answerWords = getGameWords(words, wordsPositions, findTodayNumber())
    const tiles = fx.shuffleArray(fx.getTiles(answerWords))
    const initCurrent = {allAnswerWords: answerWords, answerWords, tiles, guesses: [], pos: {row: 0, col: 0}, msg:'', results: [], gameStreak: 0, streak: 0, coreFound: [], validFound: [], time: 0, date: new Date().toLocaleDateString('en-US')}
    
    if(!ls) {
      setPage('instructions')
      localStorage.setItem('wf-game', JSON.stringify({
        status: 'start',
        settings: initSettings,
        current: {...initCurrent},
        records: {playedGames: 0, bestTimes: [], totalWordsFound: 0, bestStreak: 0}
      }))
    } else {
      ls.status === 'start' && setPage('instructions')
      const currentDate = new Date(ls.current.date)
      currentDate.setHours(0,0,0,0)
      const today = new Date()
      today.setHours(0,0,0,0)

      if(today > currentDate){
        if(ls.status === 'resume'){
          // Game did not finished, but the day is over
          ls.records = {...ls.records, 
            playedGames: ls.records.playedGames + 1
          }
        }
        // Reset status and current game data for new day
        ls.status = 'start'
        ls.current = {...initCurrent}
        if(Number(ls.settings.wordsToSolve) === 2) {
            ls.current.answerWords = ls.current.allAnswerWords.slice(0,2)
            ls.current.tiles = fx.getTiles(ls.current.answerWords)
        }
        localStorage.setItem('wf-game', JSON.stringify({...ls}))
      }
    }
    setInitialLoad(false)
    setDailyNo(fx.calculateDailyChallengeNumber(launchDate))
    setSettings(JSON.parse(localStorage.getItem('wf-game')).settings)
  }, [])

  const [returnPage, setReturnPage] = useState('home')

  useEffect(() => {
    interval.current = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('wf-game'))
    if(ls){
      setCurrentTime(ls.current.time)
      if(ls.status === 'resume'){
        if(trackingTime) {
          ls.current.time += 1
          setCurrentTime(ls.current.time)
          localStorage.setItem('wf-game', JSON.stringify({...ls}))
        }
        if(!trackingTime && page === 'game') return setTrackingTime(true)
      }

      if(ls.status === 'finish' || ls.status === 'wait'){
        const diff = Math.floor((nextMidnight.getTime() - new Date().getTime()) / 1000)
        if(diff <= -1) {
          clearInterval(interval.current)
          return //window.location.reload()
        }
        diff >= 0 && setSecsTillMidnight(diff)
      }
    }
  }, [time])
  

  const finish = () => {
    const ls = JSON.parse(localStorage.getItem('wf-game'))
    // Game is finished, updating records
    if(ls.status !== 'finish'){
      ls.records = {...ls.records, 
        playedGames: ls.records.playedGames + 1,
        bestTimes: fx.recordNewBest(ls.records.bestTimes, ls.current.time, numberOfTopTimesToRecord),
        totalWordsFound: ls.records.totalWordsFound + ls.current.coreFound.length + ls.current.validFound.length,
        bestStreak: ls.current.gameStreak > ls.records.bestStreak ? ls.current.gameStreak : ls.records.bestStreak,
        }
      ls.status = 'finish'
    }
    localStorage.setItem('wf-game', JSON.stringify({...ls}))
    setTimeout(() => {
      const lsT = JSON.parse(localStorage.getItem('wf-game'))
        const el = document.getElementById('finished')
        if(el) el.style.display = 'none'
        setReturnPage('home')
        if(lsT.status !== 'wait') setPage('stats')
        setTrackingTime(false)
    }, animationTimeInSecs * 1000);
  }

  useEffect(() => {
    const ls = JSON.parse(localStorage.getItem('wf-game'))
    if(trackingTime && page === 'home') {
      setTrackingTime(false)
    }
    if(ls.status === 'finish' && page !== 'stats'){
      ls.status = 'wait'
      localStorage.setItem('wf-game', JSON.stringify({...ls}))
    }
    if(page === 'game') setShowTop(false)
  }, [page])

  const updateSettings = (newSettings) => {
    setSettings(newSettings)
  }

  const goToGamePage = () => {
    setPage('game')
    setReturnPage('game')
  }

  return (
    <div className={settings.darkMode ? 'app' : 'app app-light'}>
      <div className={settings.darkMode ? 'layout' : 'layout layout-light'}>
        {initialLoad ? <div className='loader-container'><Loader /></div>
        :<>
        <Header nav={(name)=>setPage(name)} setReturn={(name)=>setReturnPage(name)} settings={settings}/>
        {page === 'home' &&
        <Home goGame={goToGamePage} secsTillMidnight={secsTillMidnight} daily={dailyNo} settings={settings}/>}
        {page === 'settings' &&
        <Settings goBack={()=>setPage(returnPage)} maxWords={maxWordsToSolve} updateSettings={updateSettings} settings={settings}/>}
        {page === 'stats' &&
        <Stats goBack={()=>setPage(returnPage)} secs={currentTime} daily={dailyNo} showTop={showTop} setShowTop={setShowTop} settings={settings} />}
        {page === 'instructions' &&
        <Instructions goGame={goToGamePage} goBack={()=>setPage(returnPage)} settings={settings}/>}
        {page === 'game' &&
        <Game mwl={maxWordLength} finishGame={finish} setShowTop={setShowTop} settings={settings} />}
        </>}
      </div>
    </div>
  )
}

export default App;
