import React from 'react'
import './tile.css'

export default function Tile({demo, idx, letter, times, placement, dark, highContrast}) {
    return (
        <>
        {demo ?
        <div className={dark ? highContrast ? 'high-contrast-dark tile-demo': 'tile-demo' : highContrast ? 'high-contrast-light tile-demo' : 'light-tile tile-demo'}>
            <div className='tile-times'>{times}</div>
            <div className='tile-letter'>{letter}</div>
        </div>
        :
        <div className={times > 0 ? dark ? highContrast ? 'high-contrast-dark tile pointer' : 'tile pointer' : highContrast ? 'high-contrast-light light-tile tile pointer' : 'light-tile tile pointer' : 'tile invisible'} onClick={(e)=> times > 0 && placement(idx)}>
            {times > 1 && <div className='tile-times'>{times}</div>}
            <div className='tile-letter'>{letter}</div>
        </div>
        }
        </>
    )
}
