import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from './loader/Loader'
import Tile from './tile/Tile'

export default function Instructions({goBack, goGame, settings}) {
    const [status, setStatus] = useState('')

    // Loader
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const ls = JSON.parse(localStorage.getItem('wf-game'))
        if(!ls) return
        setStatus(ls.status)
        setTimeout(() => {
            setLoader(false)
        }, 500);
    }, [])

    return (
        <section id='instructions'>
            {loader ? <div className='loader-container'><Loader /></div>
            :<>
            <div className='section-title flexrow'>
                <div className='empty'></div>
                <p className='title'>How to play</p>
                <FontAwesomeIcon className='icon-close pointer' icon="fa-solid fa-circle-xmark" onClick={goBack}/>
            </div>
            <div className='section-content'>
                <p>Simply guess the words with the letter tiles provided.</p>
                <p>Each guess must be a valid word.</p>
                <p>If the guess is valid the word will <br/>change colour.</p>
                <div className='valid-examples'>
                    <div className='flexcol'>
                        <p>Valid word</p>
                        <div className='valid-word'>
                            <span>B</span>
                            <span>I</span>
                            <span>D</span>
                            <span>E</span>
                            <span>T</span>
                        </div>
                    </div>
                    <div className='flexcol'>
                        <p>Valid core word</p>
                        <div className='valid-core-word'>
                            <span>D</span>
                            <span>E</span>
                            <span>B</span>
                            <span>I</span>
                            <span>T</span>
                        </div>
                    </div>
                </div>
                <div className='tile-example'>
                    <p>Some letters are used multiple times, as indicated by the number on the tile.</p>
                    <Tile demo={true} letter={'A'} times={'3'} dark={settings.darkMode} highContrast={settings.highContrastColours}/>
                </div>
                <p>A game is complete when all the tiles are cleared with valid or core words. <br/>Good luck.</p>
                {status !== 'finish' && status !== 'wait' && <button className='button-radius-5' onClick={goGame}>Play</button>}
            </div>
            </>}
        </section>
    )
}
