const fx = {
    formatTime: (secs) => {
        const hours = Math.floor(secs / 3600)
        const minutes = Math.floor((secs - hours*3600) / 60)
        const seconds = Math.floor(secs - hours*3600 - minutes*60)
        return `${hours > 0 ? String(hours)+'h' : ''}${minutes >= 0 ? minutes === 0 ? hours > 0 ? '0m' : '' : String(minutes)+'m': ''}${minutes > 0 ? String(seconds).padStart(2,'0') : String(seconds)}s`
    },
    formatTimeWithColons: (secs) => {
        const hours = Math.floor(secs / 3600)
        const minutes = Math.floor((secs - hours*3600) / 60)
        const seconds = Math.floor(secs - hours*3600 - minutes*60)
        return `${hours > 0 ? String(hours)+'h:' : ''}${minutes >= 0 ? minutes === 0 ? hours > 0 ? '0m:' : '' : String(minutes)+'m:': ''}${minutes > 0 ? String(seconds).padStart(2,'0') : String(seconds)}s`
    },
    recordNewBest: (array, time, maxLength) => {
        if(array.includes(time)) return array.sort((a,b) => a-b).slice(0, maxLength)
        return array.concat([time]).sort((a,b) => a-b).slice(0, maxLength)
    },
    calculateDailyChallengeNumber: (begin) => {
        const beginning = new Date(begin).getTime()
        const diff = new Date().getTime() - beginning
        const days = Math.floor(diff / (1000 * 3600 * 24))
        return days + 1
    },
    shuffleArray: (array) => {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }
        return array
    },
    getTiles: (wordsArray) => {
        const temp = []
        wordsArray.join('').split('').forEach(l => {
            if(!temp.find(el => el.l === l.toUpperCase())) {
            temp.push({l, t:1, left:1})
            } else {
            const idx = temp.findIndex(el => el.l === l.toUpperCase())
            temp[idx].t += 1
            temp[idx].left += 1
            }
        })
        return temp
    }
}

export default fx