import React from 'react'

import logo from '../media/logo.svg'
import darkLogo from '../media/dark-logo.svg'

import dHome from '../media/header-icons/ws_home.png'
import lHome from '../media/header-icons/ds_home.png'

import dSettings from '../media/header-icons/ws_settings.png'
import lSettings from '../media/header-icons/ds_settings.png'

import dStats from '../media/header-icons/ws_stats.png'
import lStats from '../media/header-icons/ds_stats.png'

import dHelp from '../media/header-icons/ws_help.png'
import lHelp from '../media/header-icons/ds_help.png'

export default function Header({nav, settings, setReturn}) {
    return (
        <div id='header'>
            <div className='nav-icon pointer' style={{backgroundImage: `url(${settings.darkMode ? dHome : lHome})`}}
            onClick={(e)=> {
                e.preventDefault()
                nav('home') 
                setReturn('home')
            }}></div>
            <div className='nav-icon pointer' style={{backgroundImage: `url(${settings.darkMode ? dSettings : lSettings})`}}
            onClick={(e)=>{
                e.preventDefault()
                nav('settings')
            }}/>
            <img src={settings.darkMode ? darkLogo : logo} alt='app-logo'/>
            <div className='nav-icon pointer' style={{backgroundImage: `url(${settings.darkMode ? dStats : lStats})`}}
            onClick={(e)=>{
                e.preventDefault()
                nav('stats')
            }}></div>
            <div className='nav-icon pointer' style={{backgroundImage: `url(${settings.darkMode ? dHelp : lHelp})`}}
            onClick={(e)=>{
                e.preventDefault()
                nav('instructions')
            }}></div>
        </div>
    )
}
